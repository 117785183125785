import { Col, Container } from 'react-bootstrap';
import styled from 'styled-components';

export const HomeBannerContainer = styled(Container)`
  width: 100% !important;
  max-width: 100% !important;
  margin-top: 220px;
  @media (max-width: 1399px) {
    margin-top: 200px;
  }
  @media (max-width: 1199px) {
    margin-top: 310px;
  }
  @media (max-width: 991px) {
    margin-top: 0;
  }
`;

export const HomeBannerCol = styled(Col)``;
